import * as DOMPurify from 'dompurify';

document.addEventListener('DOMContentLoaded', () => {

    const secret_key = process.env.SECRET_KEY;
    const project_id = process.env.PROJECT_ID;
    const wp_home = process.env.WP_HOME;
    const tracking_endpoint = process.env.TRACKING_ENDPOINT;

    if(secret_key === undefined || project_id === undefined) {
        console.error('SECRET_KEY or PROJECT_ID is not defined in .env file');
        return;
    }

    const timestamp  = new Date().toISOString();

    const aiSearchItems = document.querySelectorAll('.ai__trigger__open');
    const aiPromptItems = document.querySelectorAll('.js__submit__ai__prompt');
    const chatListContainer = document.querySelector('.ai__chat__list');
    const validationItems = document.querySelectorAll('.ai__block__input__validation');
    const popupInputField = document.querySelector('.ai__chat .js__submit__ai__prompt')
    const aiChatBlockElement = document.querySelector('.ai__chat__block')

    let isFirstQuestion = true;

    document.querySelector('.ai__chat__reset').addEventListener('click', () => {
        // remove dom elements
        chatListContainer.innerHTML = '';

        // reset session
        window.session_id = null;

        // reset firstQuestion
        isFirstQuestion = true
    });

    // click submit button or click on slider question
    aiSearchItems.forEach(element => {
        element.addEventListener('click', () => {
            let parentElement = element.closest('.ai__block__trigger');
            let closestInputElement = parentElement.querySelector('.js__submit__ai__prompt');

            // if clicked element has class 'ai__slide', set text to input value(question)
            if (element.classList.contains('ai__slide')) {
                closestInputElement.value = element.textContent
            }

            const clean = DOMPurify.sanitize(closestInputElement.value, { USE_PROFILES: { html: false } });
            getResponse(clean);
        });
    });

    // question input on enter
    aiPromptItems.forEach(element => {
        element.addEventListener('keypress', function (e) {
            if (e.key === 'Enter') {
                e.preventDefault();

                const clean = DOMPurify.sanitize(element.value, { USE_PROFILES: { html: false } });
                getResponse(clean);
            }
        });
    });

    function emptyInputs() {
        const ai_prompts = document.querySelectorAll('.js__submit__ai__prompt');
        ai_prompts.forEach(prompt => {
            prompt.value = '';
        });
    }

    function scrollToFirstQuestion() {
        let items = document.querySelectorAll('.ai__chat__item');
        let firstElement = items[0];
        let firstElementScroll = firstElement.querySelector('.ai__chat__item__scroll')
        if (firstElementScroll) firstElementScroll.scrollIntoView({ behavior: 'smooth' });
    }

    function blurInputField() {
        const aiPromptItems = document.querySelectorAll('.js__submit__ai__prompt');
        aiPromptItems.forEach(function(input) {
            input.blur();
        });
    }
    function emptyInputField() {
        const aiPromptItems = document.querySelectorAll('.js__submit__ai__prompt');
        aiPromptItems.forEach(prompt => {
            prompt.value = '';
        });
    }

    function addLockClass() {
        document.body.classList.add('ai__locked');
    }

    function removeLockClass() {
        document.body.classList.remove('ai__locked');
    }

    // This function will be called when ``aiSearchItems`` is clicked or when Enter is pressed
    function getResponse(value) {

        // If input is empty
        if (value.trim() === '') {
            validationItems.forEach(element => {
                element.style.display = 'block';
            });

            value.focus();
            return;
        }

        chatListContainer.classList.add('ai__chat__list__open')

        aiChatBlockElement.classList.remove('ai__chat__block__small')

        addLockClass()

        blurInputField()

        // Run this code only first time
        if(isFirstQuestion === true) {
            document.body.classList.add('ai__open');

            // Reset html in chatListContainer
            chatListContainer.innerHTML = '';

            isFirstQuestion = false
        }


        // Starting HTML
        let ChatStartingPoint = `
            <div class="ai__chat__item">
                <div class="ai__chat__item__scroll"></div>
                <div class="ai__chat__bot">
                    <div class="ai__chat__bot__icon__wrapper">
                        <div class="ai__chat__bot__icon"></div>
                    </div>
                    <div class="ai__chat__bot__output">
                        ${value}
                    </div>
                </div>

                <div class="ai__chat__user">
                    <div class="ai__chat__user__icon__wrapper">
                        <div class="ai__chat__user__icon"></div>
                    </div>
                    <div class="ai__chat__user__output__wrapper">
                        <div class="ai__skeleton">
                            <div class="ai__skeleton__shine"></div>
                            <div class="ai__skeleton__shine" style="width: 80%;"></div>
                            <div class="ai__skeleton__shine"></div>
                            <div class="ai__skeleton__shine" style="width: 43%;"></div>
                            <div class="ai__skeleton__shine" style="width: 43%;"></div>
                            <span style="width: 100%;">…we zijn bezig met het ophalen van de informatie. Bedankt voor je geduld!</span>
                        </div>
                    </div>
                </div>
            </div>

        `;

        // Append ChatStartingPoint to chatListContainer div
        chatListContainer.insertAdjacentHTML('afterbegin', ChatStartingPoint);

        getSessionId().then(() => {
            // console.log('sessionId gevonden' + sessionId);
            fetchCustomGPTStream(value);
        }, () => {
            // console.log('kan geen sessie krijgen');
        });
    }

    function fetchCustomGPTStream(value){
        // fetch data
        const options = {
            method: 'POST',
            headers: {
                accept: 'application/json',
                'content-type': 'application/json',
                authorization: 'Bearer '+ secret_key,
            },
            body: JSON.stringify({
                response_source: 'default',
                prompt: value,
                chatbot_model: 'gpt-4-o',
            }),
        };
        fetch('https://app.customgpt.ai/api/v1/projects/' + project_id + '/conversations/' + window.session_id + '/messages?stream=true&lang=nl', options)
            .then(response => {
                const reader = response.body.getReader();
                let fakeDiv = document.createElement('div');
                emptyInputs();
                scrollToFirstQuestion();

                return new ReadableStream({
                    start: function (controller) {
                        function push() {
                            reader.read()
                                .then(({done, value}) => {
                                    if (done) {
                                        controller.close();
                                        removeLockClass();
                                        popupInputField.focus();
                                        return;
                                    }

                                    let chunk = new TextDecoder().decode(value);
                                    controller.enqueue(value);
                                    getMessage(chunk, fakeDiv);

                                    push();
                                })
                        }
                        push();
                    },
                });
            })
            .catch(err => console.error(err));

        track({
            activity_type: 'gpt_added_message',
            activity: 'user added message in conversation',
            extra_data: {
                gpt_session: window.session_id,
                message: value,
            },
        });

    }
    function getSessionId() {
        return new Promise((resolve) => {
            if (window.session_id) {
                resolve(window.session_id);
            } else {
                const options = {
                    method: 'POST',
                    headers: {
                        accept: 'application/json',
                        'content-type': 'application/json',
                        authorization: 'Bearer ' + secret_key,
                    },
                    body: JSON.stringify({name: 'Bomen zijn relaxed' + timestamp}),
                };
                fetch('https://app.customgpt.ai/api/v1/projects/' + project_id + '/conversations', options)
                    .then((response) => {
                            response.json().then(json => {
                                window.session_id = json.data.session_id;

                                track({
                                    activity_type: 'gpt_started_conversation',
                                    activity: 'user started conversation',
                                    extra_data: {
                                        gpt_session: window.session_id,
                                    },
                                });

                                resolve(window.session_id)
                            });
                        },
                    )
            }
        });
    }

    function track(data = {}) {
        if (!tracking_endpoint) {
            return;
        }

        let application = 'website';
        if (window.location.href.includes('bzb')) {
            application = 'bzb';
        } else if (window.location.href.includes('kennisbank')) {
            application = 'kennisbank';
        }

        data.account_guid = window.user_account_guid;
        data.application = application;
        return fetch(
            tracking_endpoint,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            }
        ).catch();
    }

    function getMessage(messageStream, fakeDiv) {

        let lines = messageStream.split('\n');

        // add new question
        let lastItem = document.querySelector('.ai__chat__item:first-child')
        let lastItemWrapper = lastItem.querySelector('.ai__chat__user__output__wrapper')

        // Process each line
        for(let i = 0; i < lines.length; i++) {
            let line = lines[i];

            if(line.startsWith('data:')){
                // Remove the "data: " prefix // 'data: {"status":"progress","message":"D"}';
                let jsonString = line.substring(5);

                // Parse the JSON string into an object
                let dataObject = JSON.parse(jsonString);

                if(dataObject.status === 'progress' && dataObject.message){
                    fakeDiv.innerHTML += dataObject.message;

                    fakeDiv.innerHTML = boldFormatting(fakeDiv.innerHTML);
                    fakeDiv.innerHTML = convertLinks(fakeDiv.innerHTML);
                    fakeDiv.innerHTML = convertNewLines(fakeDiv.innerHTML);

                    lastItemWrapper.innerHTML = `
                    <div class="ai__chat__user__output">
                        ${fakeDiv.innerHTML}
                    </div>
                    `;
                }
            }
            let allLinks = fakeDiv.querySelectorAll('a');
            allLinks.forEach(link => link.setAttribute('target', '_blank'));

        }
    }


    function boldFormatting(text){
        return text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    }

    function convertLinks(text){
        if (!wp_home.includes('bzb.')){
            text = text.replace('bzb.', '');
        }
        return text.replace(/\[([^[\]]+)\]\(([^)]+)\)/g, '<a href="$2">$1</a>');
    }

    function convertNewLines(text){
        return text.replace(/\n/g, '<br>');
    }


    // Trigger close button
    const aiClose = document.querySelectorAll('.ai__trigger__close');

    aiClose.forEach(element => {
        element.addEventListener('click', () => {
            document.body.classList.remove('ai__open');

            window.session_id = null;

            removeLockClass()

            isFirstQuestion = true

            emptyInputField()
        });
    });



    // Trigger reset button
    const aiReset = document.querySelectorAll('.ai__chat__reset');

    aiReset.forEach(element => {
        element.addEventListener('click', () => {
            isFirstQuestion = true

            window.session_id = null;

            emptyInputField()

            chatListContainer.innerHTML = '';

            popupInputField.focus()
        });
    });


    // Open modal on click chat icon
    const fixedChat = document.querySelectorAll('.ai__fixed')

    fixedChat.forEach(element => {
        element.addEventListener('click', () => {
            document.body.classList.add('ai__open');

            chatListContainer.innerHTML = '';

            popupInputField.focus()
        });
    });




    // Slick slider
    let slidesToShow = 2;

    if(window.location.href.includes('kennisbank')) {
        slidesToShow = 1;
    }

    $('.ai__slider').slick({
        dots: false,
        cssEase: 'linear',
        autoplay: true,
        slidesToShow: slidesToShow,
        draggable: true,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
        prevArrow: '<span class="slick--prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" style="fill: #88CAC7;"><!--!Font Awesome Pro 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M15 239c-9.4 9.4-9.4 24.6 0 33.9L207 465c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L65.9 256 241 81c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L15 239z"/></svg></span>',
        nextArrow: '<span class="slick--next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" style="fill: #88CAC7;"><!--!Font Awesome Pro 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M305 239c9.4 9.4 9.4 24.6 0 33.9L113 465c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l175-175L79 81c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L305 239z"/></svg></span>',
    });


// Chat slider with example questions
    $('.ai__slider__small').slick({
        dots: false,
        cssEase: 'linear',
        autoplay: true,
        slidesToShow: 1,
        draggable: true,
        swipeToSlide: true,
        prevArrow: '<span class="slick--prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" style="fill: #88CAC7;"><!--!Font Awesome Pro 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M15 239c-9.4 9.4-9.4 24.6 0 33.9L207 465c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L65.9 256 241 81c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L15 239z"/></svg></span>',
        nextArrow: '<span class="slick--next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" style="fill: #88CAC7;"><!--!Font Awesome Pro 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M305 239c9.4 9.4 9.4 24.6 0 33.9L113 465c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l175-175L79 81c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L305 239z"/></svg></span>',
    });


});

